import {
    FBA_cascadeNotifications,
    FBA_displayCountryFlags,
    FBA_emailAttachment,
    FBA_enableKatmaiReads,
    FBA_followUps,
    FBA_integrationFacebook,
    FBA_integrationMsTeams,
    FBA_integrationSlack,
    FBA_integrationTwitter,
    FBA_integrations,
    FBA_internalConference,
    FBA_lockboxShared,
    FBA_mfa,
    FBA_opt_in,
    FBA_recipientSchedule,
    FBA_scenarioLibrary,
    FBA_smsSegmentCount,
    FBA_riskintelligenceFF,
    FBA_nextGenSendingEngine,
    FBA_assetDatastore,
    createDoNotContact,
    deleteDoNotContact,
    editDoNotContact,
    viewDoNotContact
} from 'common/constants/permissions';

const canDisplayCountryFlags = permissions => !!permissions[FBA_displayCountryFlags];
const canManageRecipientSchedule = permissions => !!permissions[FBA_recipientSchedule];
const canManageInternalConferenceResponse = permissions => !!permissions[FBA_internalConference];
const canManageTwitter = permissions => !!permissions[FBA_integrationTwitter];
const canManageFollowUps = permissions => !!permissions[FBA_followUps];
const canManageSmsOptIn = permissions => !!permissions[FBA_opt_in];
const canManageCascadeNotifications = permissions => !!permissions[FBA_cascadeNotifications];
const canManageEmailAttachment = permissions => !!permissions[FBA_emailAttachment];
const canManageSMSSegments = permissions => !!permissions[FBA_smsSegmentCount];
const canManageScenarioLibrary = permissions => !!permissions[FBA_scenarioLibrary];
const canViewMFA = permissions => !!permissions[FBA_mfa];
const canViewAdvancedContactsFilter = permissions => !!permissions[FBA_enableKatmaiReads];
const canViewAdvancedGroupFilter = permissions => !!permissions[FBA_enableKatmaiReads];
const canManageIntegrations = permissions => !!permissions[FBA_integrations];
const canManageFacebook = permissions => !!permissions[FBA_integrationFacebook];
const canManageSlack = permissions => !!permissions[FBA_integrationSlack];
const canManageMSTeams = permissions => !!permissions[FBA_integrationMsTeams];
const canManageLockBox = permissions => !!permissions[FBA_lockboxShared];
const canManageRiskIntelligence = permissions => !!permissions[FBA_riskintelligenceFF];
const canCreateDoNotContact = permissions => !!permissions[createDoNotContact];
const canViewDoNotContact = permissions => !!permissions[viewDoNotContact];
const canEditDoNotContact = permissions => !!permissions[editDoNotContact];
const canDeleteDoNotContact = permissions => !!permissions[deleteDoNotContact];
const usesNextGenSendingEngine = permissions => !!permissions[FBA_nextGenSendingEngine];
const canAccessAssetUI = permissions => !!permissions[FBA_assetDatastore];

export default {
    canDisplayCountryFlags,
    canManageRecipientSchedule,
    canManageInternalConferenceResponse,
    canManageFollowUps,
    canManageSmsOptIn,
    canManageCascadeNotifications,
    canManageEmailAttachment,
    canManageSMSSegments,
    canManageTwitter,
    canManageScenarioLibrary,
    canViewMFA,
    canViewAdvancedContactsFilter,
    canViewAdvancedGroupFilter,
    canManageIntegrations,
    canManageFacebook,
    canManageSlack,
    canManageMSTeams,
    canManageLockBox,
    canCreateDoNotContact,
    canEditDoNotContact,
    canViewDoNotContact,
    canDeleteDoNotContact,
    canManageRiskIntelligence,
    usesNextGenSendingEngine,
    canAccessAssetUI
};
