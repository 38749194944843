import ApiService from './api.service';
export default class OrganizationService extends ApiService {
    constructor(ServiceUrl) {
        super(ServiceUrl);
    }
    GetBrandByNamespace = async (namespace) => {
        try {
            const response = await this.get(`api/v2/Branding/${namespace}`);
            return response;
        }
        catch (error) {
            console.error(error);
            return {};
        }
    };
    GetCustomNavigation = async () => {
        try {
            const response = await this.get('api/v1/organization/custom/navigation');
            return response;
        }
        catch (error) {
            console.error(error);
            return {};
        }
    };
}
